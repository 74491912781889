import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layouts/index.vue";
export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    redirect: "/home",
    meta: {
      title: "首页",
      keepAlive: false
    },
    component: Layout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Home/Home.vue"),
        meta: { title: "首页", keepAlive: true, showTab: true }
      },
      {
        path: "/goods",
        name: "Goods",
        component: () => import("@/views/Goods/Goods.vue"),
        meta: { title: "数藏", keepAlive: true, showTab: true }
      },
      {
        path: "/author",
        name: "Author",
        component: () => import("@/views/Author/Author.vue"),
        meta: { title: "艺术家", keepAlive: true, showTab: true }
      },
      {
        path: "/user",
        name: "User",
        component: () => import("@/views/User/User.vue"),
        meta: { title: "我的", keepAlive: true, showTab: true, withAuth: true }
      },
      {
        path: "/commodity",
        name: "Commodity",
        component: () => import("@/views/commodity/Index.vue"),
        meta: { title: "商城", keepAlive: true, showTab: true, withAuth: false }
      },
      {
        path: "/commodity-detail/:id",
        name: "CommodityDetail",
        component: () => import("@/views/commodity/Detail.vue"),
        meta: { title: "详情", keepAlive: false, withAuth: false }
      },
      {
        path: "/commodity-order-pay/:id",
        name: "CommodityOrderPay",
        component: () => import("@/views/commodity/OrderPay.vue"),
        meta: { title: "订单", keepAlive: false, withAuth: false }
      },
      {
        path: "/commodity-order-list",
        name: "CommodityOrderList",
        component: () => import("@/views/commodity/OrderList.vue"),
        meta: { title: "订单列表", keepAlive: false, withAuth: false }
      },
      {
        path: "/market",
        name: "Market",
        component: () => import("@/views/Market/Index.vue"),
        meta: { title: "寄售", keepAlive: true, showTab: true, withAuth: false }
      },
      {
        path: "/market-list",
        name: "MarketList",
        component: () => import("@/views/Market/List.vue"),
        meta: { title: "寄售藏品", keepAlive: false, withAuth: false }
      },
      {
        path: "/market-list-series",
        name: "MarketListSeries",
        component: () => import("@/views/Market/ListSeries.vue"),
        meta: { title: "加密豚", keepAlive: true, showTab: true, withAuth: false }
      },
      {
        path: "/market-list-order-deal-series",
        name: "MarketListOrderDealSeries",
        component: () => import("@/views/Market/ListOrderDealSeries.vue"),
        meta: { title: "最近成交", keepAlive: false, withAuth: false }
      },
      {
        path: "/market-list-order-deal",
        name: "MarketListOrderDeal",
        component: () => import("@/views/Market/ListOrderDeal.vue"),
        meta: { title: "最近成交", keepAlive: false, withAuth: false }
      },
      {
        path: "/market-info",
        name: "MarketInfo",
        component: () => import("@/views/Market/Info.vue"),
        meta: { title: "藏品详情", keepAlive: false, withAuth: false }
      },
      {
        path: "/market/user/manage",
        name: "MarketUserManage",
        component: () => import("@/views/Market/User/Manage.vue"),
        meta: { title: "寄售钱包管理", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/openacct-activate",
        name: "OpenacctActivate",
        component: () => import("@/views/Market/User/OpenacctActivate.vue"),
        meta: { title: "开户激活", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/balance-details",
        name: "MarketUserBalanceDetails",
        component: () => import("@/views/Market/User/BalanceDetails.vue"),
        meta: { title: "余额明细", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/bank-card",
        name: "MarketUserBankCard",
        component: () => import("@/views/Market/User/BankCard.vue"),
        meta: { title: "银行卡管理", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/bank-card-add",
        name: "MarketUserBankCardAdd",
        component: () => import("@/views/Market/User/BankCardAdd.vue"),
        meta: { title: "银行卡添加", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/pass-info",
        name: "PassInfo",
        component: () => import("@/views/Market/User/PassInfo.vue"),
        meta: { title: "修改密码", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/recharge-and-withdrawal",
        name: "MarketUserRechargeAndWithdrawal",
        component: () => import("@/views/Market/User/RechargeAndWithdrawal.vue"),
        meta: { title: "充值提现", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/artword-publish",
        name: "MarketUserArtwordPublish",
        component: () => import("@/views/Market/User/ArtwordPublish.vue"),
        meta: { title: "藏品寄售", keepAlive: false, withAuth: true }
      },
      {
        path: "/market/user/order-pay/:id",
        name: "MarketUserOrderPay",
        component: () => import("@/views/Market/User/OrderPay.vue"),
        meta: { title: "待付款", keepAlive: false }
      },
      {
        path: "/notice",
        name: "Notice",
        component: () => import("@/views/Notice/Notice.vue"),
        meta: { title: "公告详情", keepAlive: false }
      },
      {
        path: "/notice-list",
        name: "NoticeList",
        component: () => import("@/views/Notice/NoticeList.vue"),
        meta: { title: "公告列表", keepAlive: false }
      },
      {
        path: "/goods-detail",
        name: "GoodsDetail",
        component: () => import("@/views/Goods/GoodsDetail.vue"),
        meta: { title: "藏品详情", keepAlive: false, withAuth: false }
      },
      {
        path: "/goods-vipdetail",
        name: "GoodsVipDetail",
        component: () => import("@/views/Goods/GoodsVipDetail.vue"),
        meta: { title: "详情", keepAlive: false, withAuth: false }
      },
      {
        path: "/author-detail",
        name: "AuthorDetail",
        component: () => import("@/views/Author/AuthorDetail.vue"),
        meta: { title: "艺术家详情", keepAlive: false }
      },
      // user
      {
        path: "/user-artworks-group",
        name: "UserArtworksGroup",
        component: () => import("@/views/User/goods/UserArtworksGroup.vue"),
        meta: { title: "我的藏品", keepAlive: false }
      },
      {
        path: "/user-artworks-goods",
        name: "UserArtworksGoods",
        component: () => import("@/views/User/goods/UserArtworksGoods.vue"),
        meta: { title: "藏品详情", keepAlive: false }
      },
      // user
      {
        path: "/user-goods-detail",
        name: "UserGoodsDetail",
        component: () => import("@/views/User/goods/UserGoodsDetail.vue"),
        meta: { title: "藏品详情", keepAlive: false }
      },
      {
        path: "/user-goods-give",
        name: "UserGoodsGive",
        component: () => import("@/views/User/goods/UserGoodsGive.vue"),
        meta: { title: "藏品转赠", keepAlive: false }
      },
      // order
      {
        path: "/user-order",
        name: "UserOrder",
        component: () => import("@/views/User/order/OrderTabs.vue"),
        meta: { title: "订单", keepAlive: false }
      },
      {
        path: "/user-order-pay/:id",
        name: "UserOrderPay",
        component: () => import("@/views/User/order/OrderPay.vue"),
        meta: { title: "待付款", keepAlive: false }
      },
      // settings
      {
        path: "/artwork-campaign-amalgam",
        name: "ArtworkCampaignAmalgam",
        component: () => import("@/views/User/ArtworkCampaign/Amalgam.vue"),
        meta: { title: "合成活动", keepAlive: false }
      },
      {
        path: "/artwork-campaign-raffle",
        name: "ArtworkCampaignRaffle",
        component: () => import("@/views/User/ArtworkCampaign/Raffle.vue"),
        meta: { title: "抽奖活动", keepAlive: false }
      },
      {
        path: "/artwork-campaign-raffle-history",
        name: "ArtworkCampaignRaffleHistory",
        component: () => import("@/views/User/ArtworkCampaign/RaffleHistory.vue"),
        meta: { title: "抽奖历史", keepAlive: false }
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("@/views/User/settings/SettingsList.vue"),
        meta: { title: "设置", keepAlive: false }
      },
      {
        path: "/settings-identify",
        name: "Identify",
        component: () => import("@/views/User/settings/Identify.vue"),
        meta: { title: "实名认证", keepAlive: false }
      },
      {
        path: "/settings-recommend",
        name: "Recommend",
        component: () => import("@/views/User/settings/Recommend.vue"),
        meta: { title: "推荐码", keepAlive: false }
      },
      {
        path: "/settings-password",
        name: "Password",
        component: () => import("@/views/User/settings/Password.vue"),
        meta: { title: "修改密码", keepAlive: false }
      },
      {
        path: "/settings-info",
        name: "Info",
        component: () => import("@/views/User/settings/ModifyInfo.vue"),
        meta: { title: "修改资料", keepAlive: false }
      },
      {
        path: "/settings-invite",
        name: "Invite",
        component: () => import("@/views/User/settings/Invite.vue"),
        meta: { title: "推荐注册", keepAlive: false }
      },
      {
        path: "/settings-invite-list",
        name: "InviteList",
        component: () => import("@/views/User/settings/InviteList.vue"),
        meta: { title: "推荐明细", keepAlive: false }
      },
      {
        path: "/settings-about",
        name: "About",
        component: () => import("@/views/User/settings/About.vue"),
        meta: { title: "关于我们", keepAlive: false }
      },
      {
        path: "/metaverse",
        name: "Metaverse",
        component: () => import("@/views/Metaverse/index.vue"),
        meta: { title: "元宇宙", keepAlive: true, showTab: true }
      },
      // login
      {
        path: "/signin",
        name: "SignIn",
        component: () => import("@/views/Home/SignIn.vue"),
        meta: { title: "登录", keepAlive: false }
      },
      {
        path: "/fastRegister",
        name: "FastRegister",
        component: () => import("@/views/Home/FastRegister.vue"),
        meta: { title: "联合利国文交所数字版权交易中心", keepAlive: false }
      },
      {
        path: "/privacyPolicy",
        name: "PrivacyPolicy",
        component: () => import("@/views/Home/PrivacyPolicy.vue"),
        meta: { title: "隐私政策", keepAlive: false }
      },
      {
        path: "/serviceAgreement",
        name: "ServiceAgreement",
        component: () => import("@/views/Home/ServiceAgreement.vue"),
        meta: { title: "服务协议", keepAlive: false }
      },
      {
        path: "/signup",
        name: "SignUp",
        component: () => import("@/views/Home/SignUp.vue"),
        meta: { title: "注册", keepAlive: false }
      },
      {
        path: "/forget",
        name: "Forget",
        component: () => import("@/views/Home/Forget.vue"),
        meta: { title: "找回密码", keepAlive: false }
      },
      {
        path: "/wx-signin",
        name: "WX-SignIn",
        component: () => import("@/views/Home/SignInWx.vue"),
        meta: { title: "微信登录", keepAlive: false }
      },
      {
        path: "/sms-signin",
        name: "SMS-SignIn",
        component: () => import("@/views/Home/SignInSms.vue"),
        meta: { title: "短信登录", keepAlive: false }
      },

      // {
      //   path: "/demo",
      //   name: "Demo",
      //   component: () => import("@/views/tabBar/Demo.vue"),
      //   meta: { title: "测试案例", keepAlive: false, showTab: true }
      // },
      // {
      //   path: "/pkgInfo",
      //   name: "PkgInfo",
      //   component: () => import("@/views/tabBar/PkgInfo.vue"),
      //   meta: { title: "项目信息", keepAlive: false, showTab: true }
      // },
      // {
      //   path: "/tsx",
      //   name: "Tsx",
      //   component: () => import("@/test/demo"),
      //   meta: { title: "测试tsx", keepAlive: false }
      // },
      // {
      //   path: "/static",
      //   name: "Static",
      //   component: () => import("@/test/testStatic.vue"),
      //   meta: { title: "测试静态资源", keepAlive: false }
      // },
      // {
      //   path: "/cssModel",
      //   name: "CssModel",
      //   component: () => import("@/test/testCssModel"),
      //   meta: { title: "测试css-model", keepAlive: false }
      // },
      // {
      //   path: "/mockAxios",
      //   name: "MockAxios",
      //   component: () => import("@/test/testMockAxios.vue"),
      //   meta: { title: "测试mock-axios", keepAlive: false }
      // },
      // {
      //   path: "/pinia",
      //   name: "Pinia",
      //   component: () => import("@/test/testPinia.vue"),
      //   meta: { title: "测试pinia", keepAlive: false }
      // }
      // {
      //   path: "/testTimingData",
      //   name: "TestTimingData",
      //   component: () => import("@/test/testTimingData.vue"),
      //   meta: { title: "定时请求数据", keepAlive: false }
      // },
      // {
      //   path: "/testComponent",
      //   name: "TestComponent",
      //   component: () => import("@/test/testComponent.vue"),
      //   meta: { title: "测试全局组件", keepAlive: false }
      // }
      {
        path: "/test-three",
        // name: "Testthree",
        component: () => import("@/test/testThree.vue"),
        meta: { title: "测试3D", keepAlive: false }
      }
    ]
  }
];
