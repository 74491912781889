export default {
  env: "production",
  mock: true,
  title: "生产",
  baseUrl: "", // 正式项目地址
  baseApi: "https://api.cangjingling.com", // api请求地址
  authApi: "https://oauth.cangjingling.com", // 登录相关api请求地址
  sitaApi: "https://sita.cangjingling.com", // 工具辅助接口 api请求地址
  APPID: "",
  APPSECRET: "",
  $cdn: "https://imgs.solui.cn"
};
