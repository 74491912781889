// 本地环境配置
export default {
  env: "development",
  mock: true,
  title: "开发",
  baseApi: "/api", // api请求地址
  authApi: "/auth", // 登录相关api请求地址
  sitaApi: "/sita", // 工具辅助接口 api请求地址
  APPID: "",
  APPSECRET: "xxx",
  $cdn: "https://imgs.solui.cn"
};
