export default {
  env: "test",
  mock: false,
  title: "测试",
  baseUrl: "", // 测试项目地址
  baseApi: "https://api.test.cangjingling.cn", // api请求地址
  authApi: "https://oauth.test.cangjingling.cn", // 登录相关api请求地址
  sitaApi: "https://sita.test.cangjingling.cn", // 工具辅助接口 api请求地址
  APPID: "",
  APPSECRET: "xxx",
  $cdn: "https://imgs.solui.cn"
};
