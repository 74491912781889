import { setDocumentTitle } from "@/utils";
import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { routes } from "./router.config";
import { getQueryParams, phoneModel, isWeChat } from "@/utils";

// import { useAuthStore } from "@/store/auth";
import { useLinkStore } from "@/store/link";
import { fetchWeChatAuth } from "@/api/WxController";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { useUserStore } from "@/store/user";
NProgress.configure({ showSpinner: true });

interface IQueryParams {
  code?: string;
}
const router = createRouter({
  history: createWebHashHistory(import.meta.env.VITE_BASE_PATH),
  routes,
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 })
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { left: 0, top: 0 };
  //   }
  // }
});

router.beforeEach((to, from) => {
  // console.log("===router beforeEach", to, from);
  setDocumentTitle(to.meta.title as string);
  NProgress.start(); // start progress bar

  const userStore = useUserStore();

  // 接收微信登录回调参数
  if (isWeChat() && !userStore.openid) {
    const { code } = getQueryParams<IQueryParams>();
    console.log("+++ wxLogin code", code);
    if (code) {
      // userStore.setCode(code);
      // userStore.wxLogin();
      userStore.wxAuth(code);
    }
  }

  // 登录验证
  if (to.meta.withAuth) {
    if (!userStore.isAuth) {
      // // 微信浏览器内微信授权获取openid
      // if (isWeChat()) {
      //   if (!userStore.isAuth) {
      //     location.href = fetchWeChatAuth();
      //   }
      // } else {
      // }

      // 普通登录
      return { path: "/sms-signin" };
    }
  }

  // //! 解决ios微信下，分享签名不成功的问题,将第一次的进入的url缓存起来。
  // if (window.entryUrl === undefined) {
  //   window.entryUrl = location.href.split("#")[0];
  // }
});

router.afterEach((to, from) => {
  NProgress.done(); // finish progress bar

  // const linkStore = useLinkStore();
  // let url;
  // if (phoneModel() === "ios") {
  //   url = window.entryUrl;
  // } else {
  //   url = window.location.href;
  // }
  // // console.log("linkStore", linkStore);

  // // 保存url
  // linkStore.setInitLink(url);
});

export default router;
