import {
  apiAuth,
  apiGetPublicKey,
  apiLogout,
  apiModifyInfo,
  apiModifyPassword,
  apiMyModifyPassword,
  apiSignUp,
  apiToken,
  apiUserInfo,
  apiRecipientWaitingList,
  apiTaskArtwork
} from "@/api/authApi";
import { apiGetWeChatTokenByCode, apiSetWeChatOpenId, fetchWeChatAuth } from "@/api/WxController";
import { isWeChat, md5String, setEncrypt } from "@/utils";
import to from "@/utils/await-to";
import { defineStore } from "pinia";
import { Toast } from "vant/es";
import { useAppStore } from "./app";
import { config } from "@/config";

export enum LoginType {
  phone = "phone",
  weiXin = "weiXin"
}

export const useUserStore = defineStore("user", {
  state: () => ({
    // userName: "",
    // password: "",
    loginType: LoginType.phone as LoginType,
    authCode: "",
    token: "",
    openid: "",
    userInfo: {} as any
  }),
  getters: {
    isAuth: (state) => !!state.token,
    hasUserInfo: (state) => !!state.userInfo.uid,
    userPhone: (state) => state.userInfo?.phone
  },
  actions: {
    // updateState(data: any) {
    //   this.$state = data;
    //   this.updateAppConfig();
    // },
    // updateAppConfig() {
    //   const appStore = useAppStore();
    //   appStore.setData("app-update");
    // },
    // async getAuth(data) {
    //   const [err, res] = await to(apiAuth(data));
    //   debugger;
    // },
    setCode(code) {
      this.authCode = code;
    },
    setToken(token) {
      this.token = token;
    },
    setUserInfo(data?: any) {
      this.userInfo = data;
      this.openid = data.weChatToken;
    },
    clearUserInfo() {
      this.$state = {
        loginType: LoginType.phone,
        authCode: "",
        token: "",
        openid: "",
        userInfo: {}
      };
      // // 跳转登录页
      // const router = useRouter();
      // router.push({
      //   path: "/signin"
      // });
    },
    async getUserInfo() {
      if (this.token) {
        if (this.loginType === LoginType.phone) {
          const [err, res] = await to(apiUserInfo());
          if (!err && res?.data) {
            this.setUserInfo(res?.data);
          } else {
            this.clearUserInfo();
          }
        } else if (this.loginType === LoginType.weiXin) {
          // const [err, res] = await to(
          //   apiGetWeChatUserInfo({
          //     access_token: this.token,
          //     openid: this.openid
          //   })
          // );
          // if (!err && res?.data) {
          //   this.setUserInfo({
          //     ...res?.data
          //     // uid:
          //   });
          // } else {
          //   this.clearUserInfo();
          // }
        }
      }
    },
    async login(data) {
      // 验证
      if (!this.authCode) {
        const [err, res] = await to(
          apiAuth({
            ...data,
            password: data.type === "sms" ? data.password : md5String(data.password)
          })
        );
        if (!err) {
          this.authCode = res?.data?.code;
        } else {
          return;
        }
      }
      // 获取token
      if (!this.token) {
        const [err, res] = await to(apiToken({ code: this.authCode }));
        if (!err) {
          const { tokenType, accessToken } = res?.data || {};
          this.token = `${tokenType} ${accessToken}`;
        } else {
          this.clearUserInfo();
        }
      }
      // 获取用户信息
      await this.getUserInfo();
      // // 登录之后判断下是否绑定了openid
      // setTimeout(() => {
      //   this.getWxCode();
      // }, 1000);
      return true;
    },
    async logout() {
      const [err, res] = await to(apiLogout());
      if (!err) {
        this.clearUserInfo();
      }
    },
    async singUp(data) {
      const appStore = useAppStore();
      const [, resCode] = await to(apiGetPublicKey());
      const { code, apiKey } = resCode?.data || {};
      const md5Pwd = md5String(data.password);
      const enCodePassword = setEncrypt(apiKey, md5Pwd);
      // 注册接口
      const [err, res] = await to(
        apiSignUp({
          ...data,
          validateCode: data.code,
          code,
          password: enCodePassword
        })
      );
      if (!err) {
        return true;
      } else {
        throw err;
      }
    },
    async taskArtwork(accountUid: string) {
      console.log("TaskArtwork 列表param", accountUid, { accountUid: accountUid });

      Toast.success("检查是否有等待领取藏品");
      const res = await apiRecipientWaitingList(accountUid);
      console.log("TaskArtwork 列表", res.data);
      if (res.data.length === 0) return;

      Toast.success("正在领取藏品！");
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        await apiTaskArtwork(accountUid, element.uid);
      }
      Toast.success("领取藏品完成！");
    },
    async modifyPassword(password: string, phone?: string, validateCode?: string) {
      const [, res] = await to(apiGetPublicKey());
      const { code, apiKey } = res?.data || {};
      const md5Pwd = md5String(password);
      const enCodePassword = setEncrypt(apiKey, md5Pwd);
      if(this.userPhone){
        const [err, res1] = await to(
            apiMyModifyPassword({
              code,
              validateCode,
              userName: this.userPhone,
              password: enCodePassword
            })
        );
        if (res1?.status === 200) {
          return true;
        } else {
          throw err;
        }
      }
      else {
        const [err, res1] = await to(
            apiModifyPassword({
              code,
              validateCode,
              userName: phone,
              password: enCodePassword
            })
        );
        if (res1?.status === 200) {
          return true;
        } else {
          throw err;
        }
      }
    },

    // async wxLogin() {
    //   // 验证
    //   if (!this.authCode) {
    //     location.href = fetchWeChatAuth();
    //   }
    //   // 获取token
    //   if (!this.token) {
    //     const [err, res] = await to(
    //       apiGetWeChatTokenByCode({
    //         code: this.authCode
    //       })
    //     );
    //     if (res?.data.openid) {
    //       const { openid, access_token, scope, expires_in } = res?.data;
    //       console.log("weixin", openid, access_token);
    //       this.openid = openid;
    //       this.token = access_token;
    //       this.loginType = LoginType.weiXin;
    //     } else {
    //       this.clearUserInfo();
    //     }
    //   }
    //   // // 获取用户信息
    //   // this.getUserInfo();
    // }

    // /**
    //  * 获取微信openid，并保存到服务器用户信息中
    //  * @param code
    //  * @returns
    //  */
    async wxAuth(code) {
      // // 获取token
      // if (!this.openid) {
      //   const [err, res] = await to(apiGetWeChatTokenByCode({ code }));
      //   if (!res?.data.openid) {
      //     return;
      //   }
      //   const { openid } = res?.data;
      //   console.log("weixin openid:", openid);
      //   // 保存openid
      //   const [err1, res1] = await to(
      //     apiSetWeChatOpenId({
      //       userName: this.userPhone,
      //       openid
      //     })
      //   );
      //   if (res1?.status === 200) {
      //     this.openid = openid;
      //   }
      // }
    },
    // async getWxCode() {
    //   if (isWeChat() && !this.openid) {
    //     location.href = fetchWeChatAuth();
    //   }
    // },

    async modifyUserInfo(data) {
      const [err, res] = await to(
        apiModifyInfo({
          ...data,
          accountUid: this.userInfo.uid
        })
      );
      if (!err) {
        return true;
      } else {
        Toast({
          message: err.message
        });
        throw err;
      }
    }
  },
  // 开启数据缓存
  persist: {
    // key: "user",
    // storage: window.localStorage,
    // 部分保持状态的点符号路径数组。[]意味着没有状态被持久化，undefined或者null意味着整个状态被持久化。
    paths: ["token"]
  }
});
