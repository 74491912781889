import { config } from "@/config";
import request from "@/utils/request";
import { IGoodDetailPrams, IGoodPrams, IPager, IResponseType } from "./interface";

/**
 * 首页公告轮播数据
 * @param pageSize
 * @returns
 */
export const apiGetCarousel = (pageSize = 5) => {
  return request<IResponseType>({
    url: "/api/Notices/ListTop",
    method: "GET",
    loading: true,
    params: {
      pageSize
    }
  });
};

/**
 * 首页公告轮播数据
 * @param params
 * @returns
 */
export const apiGetNoticeList = (params) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Notices/List",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 首页公告轮播数据
 * @param params
 * @returns
 */
export const apiGetNoticeDetail = (params) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Notices/Detail",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询藏品列表
 * @param params
 * @returns
 */
export const apiGetAllGoodsList = (params: IGoodPrams) => {
  return request<IResponseType>({
    url: "/api/Artworks/ListH5All",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询藏品列表
 * @param params
 * @returns
 */
export const apiGetHomeGoodsList = (params: IGoodPrams) => {
  return request<IResponseType>({
    url: "/api/Artworks/ListH5Home",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询藏品列表
 * @param params
 * @returns
 */
export const apiGetClassifyGoodsList = (params: IGoodPrams) => {
  return request<IResponseType>({
    url: "/api/Artworks/ListH5Classify",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询藏品列表
 * @param params
 * @returns
 */
export const apiGetArtistGoodsList = (params: IGoodPrams) => {
  return request<IResponseType>({
    url: "/api/Artworks/ListH5Artist",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询我的藏品
 * @param params
 * @returns
 */
export const apiGetMyGoodsList = (params: IGoodPrams) => {
  return request<IResponseType>({
    url: "/api/Artworks/ArtworksList",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询商品详情
 * @param params
 * @returns
 */
export const apiGetGoodsDetail = (params: IGoodDetailPrams) => {
  return request<IResponseType>({
    url: "/api/Artworks/ArtworksInfo",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询商品详情
 * @param params
 * @returns
 */
export const apiGetGoodsDetailVip = (params: IGoodDetailPrams) => {
  return request<IResponseType>({
    url: "/api/Artworks/ArtworksInfoVip",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询我的商品详情
 * @param params
 * @returns
 */
export const apiGetMyGoodsDetail = (params) => {
  return request<IResponseType>({
    url: "/api/Artworks/MyArtworksInfo",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 查询我的商品详情
 * @param params
 * @returns
 */
export const apiGetMyGoodsDetailEx = (params) => {
  return request<IResponseType>({
    url: "/api/Artworks/MyArtworksInfoEx",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 获取分类数据
 * @returns
 */
export const apiGetGoodsCategory = () => {
  return request<IResponseType>({
    url: "/api/Parameter",
    method: "GET",
    loading: true,
    params: {
      // 暂时写死
      code: "Classify"
    }
  });
};

/**
 * 获取作家列表
 * @param params
 * @returns
 */
export const apiGetAuthorList = (params: IPager) => {
  return request<IResponseType>({
    url: "/api/Artworks/ArtistsList",
    method: "GET",
    // loading: true,
    params
  });
};

/**
 * 获取用户详情
 * @param params
 * @returns
 */
export const GetAccountInfo = (uid) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Accounts/AccountInfo?accountUid=" + uid,
    method: "GET"
  });
};

/**
 * 查询商品详情
 * @param params
 * @returns
 */
export const apiShowArtwork = (data) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Transfer/ShowArtwork",
    method: "POST",
    loading: true,
    data
  });
};

/**
 * 查询商品详情
 * @param params
 * @returns
 */
export const apiInviteSummary = (phone) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: `/api/Accounts/InviteSummaryList?name=&phone=${phone}&pageNo=1&pageSize=1`,
    method: "GET"
  });
};

/**
 * 首页公告轮播数据
 * @param params
 * @returns
 */
export const apiInviteInfoList = (params) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Accounts/InviteInfoList",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 首页公告轮播数据
 * @param params
 * @returns
 */
export const apiMyInviteInfoList = (params) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Accounts/MyInviteInfoList",
    method: "GET",
    loading: true,
    params
  });
};

/**
 * 获取元宇宙列表
 * @param params
 * @returns
 */
export const apiGetMetaverseList = (params: IPager) => {
  return request<IResponseType>({
    url: "/api/Artworks/MetaverseList",
    method: "GET",
    // loading: true,
    params
  });
};
