import { createApp } from "vue";
import store from "./store";

import App from "./App.vue";
import router from "./router";
// 引入全局样式
import "@/styles/index.scss";
// 全局引入按需引入UI库 vant
// import { vantPlugins } from './plugins/vant'

// // 调试工具
// import VConsole from "vconsole";
// const vConsole = new VConsole();

// @ts-ignore 引入svg-icon注册脚本
import "virtual:svg-icons-register";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";

const app = createApp(App);
window.vm = app;

app.use(store);
// app.use(vantPlugins)
app.use(router);
app.component("svg-icon", SvgIcon);

app.mount("#app");
