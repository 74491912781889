import { config } from "@/config";
import request from "@/utils/request";
import { IResponseType, IUserInfo } from "./interface";

/**
 * 认证
 * @param data
 * @returns
 */
export const apiAuth = (data) => {
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/connect/authorize",
    method: "POST",
    // loading: true,
    data
  });
};

/**
 * 获取token
 * @param params
 * @returns
 */
export const apiToken = (params) => {
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/connect/accessToken",
    method: "GET",
    // loading: true,
    params
  });
};

/**
 * 获取用户信息
 */
export const apiUserInfo = () => {
  return request<IResponseType<IUserInfo>>({
    baseURL: config.authApi,
    url: "/connect/userInfo",
    method: "POST"
    // loading: true
  });
};

/**
 * 退出登录
 * @returns
 */
export const apiLogout = () => {
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/connect/signOut",
    method: "POST"
  });
};

/**
 * 注册
 * @param data
 * @returns
 */
export const apiSignUp = (data) => {
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/api/Account/Register",
    method: "POST",
    data: {
      code: data.code,
      phone: data.mobile,
      password: data.password,
      inviteCode: data.inviteCode,
      validateCode: data.validateCode,
    }
  });
};

/**
 * 获取公钥
 * @returns
 */
export const apiGetPublicKey = () => {
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/api/Account/PublicKey",
    method: "GET"
  });
};

/**
 * 修改密码
 * @param data
 * @returns
 */
export const apiModifyPassword = (data) => {
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/api/Account/ModifyPassword",
    method: "POST",
    data
  });
};

/**
 * 修改密码
 * @param data
 * @returns
 */
export const apiMyModifyPassword = (data) => {
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/api/Account/MyModifyPassword",
    method: "POST",
    data
  });
};

/**
 * 修改用户信息
 * @param data
 * @returns
 */
export const apiModifyInfo = (data) => {
  return request<IResponseType>({
    timeout: 60000,
    baseURL: config.authApi,
    url: "/api/Account/ModifyAccount",
    method: "POST",
    data
  });
};


/**
 * 获取验证码
 * @param data
 * @returns
 */
export const apiSendCode = (data) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Aliyun/SmsPush",
    method: "POST",
    data: {
      // 暂时写死
      codeType: "ValidateCode",
      ...data
    }
  });
};

/**
 * 我的待领取赠送列表
 * @param data
 * @returns
 */
export const apiRecipientWaitingList = (accountUid) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Transfer/RecipientWaitingList?accountUid="+accountUid,
    method: "GET",
  });
};

/**
 * 领取等待赠送
 * @param data
 * @returns
 */
export const apiTaskArtwork = (accountUid, uid) => {
  return request<IResponseType>({
    baseURL: config.sitaApi,
    url: "/api/Transfer/TaskArtwork?accountUid="+accountUid+"&uid="+uid,
    method: "GET",
  });
};

/**
 * 检验验证码
 * @param data
 * @returns
 */
export const apiFastUserCipher = (phone) => {
  const data = {
    "openid": phone,
    "userName": ""
  }
  return request<IResponseType>({
    baseURL: config.authApi,
    url: "/api/Account/FastUserCipher",
    method: "POST",
    data
  });
};
