import { apiGetCarousel, apiGetGoodsCategory } from "@/api/homeApi";
import { defineStore } from "pinia";
import to from "@/utils/await-to";
import { ICourseItem, IGoodsItem } from "@/components/types";
import { apiSendCode } from "@/api/authApi";
import { Toast } from "vant";

export const useAppStore = defineStore("app", {
  state: () => {
    return {
      config: "app",
      // 验证码定时器
      timerCode: 0 as any,
      // 验证码手机号，暂存
      codePhone: "",
      // 验证码手机号，暂存
      codePhoneValidate: "",
      // 验证码间隔时间，单位s
      codeGiveTimeLimit: 0,
      // 首页滚动公告
      carouselList: [
        // {
        //   imageUrl: "https://t7.baidu.com/it/u=3796392429,3515260353&fm=193&f=GIF"
        // },
        // {
        //   imageUrl: "https://t7.baidu.com/it/u=825057118,3516313570&fm=193&f=GIF"
        // }
      ] as Array<ICourseItem>,
      // 分类数据列表
      categoryList: [] as Array<any>,
      // 艺术家信息
      authorList: [] as Array<any>
    };
  },
  getters: {
    // getAuthorById(state) {
    //   return (uid) => state.authorList.find((a) => a.uid === uid) || {};
    // }
  },
  actions: {
    setData(key: string, value: any) {
      this[key] = value;
    },
    getAuthorById(uid) {
      return this.authorList.find((a) => a.uid === uid) || {};
    },
    async clearTimerCode() {
      this.codeGiveTimeLimit = 0;
      return clearInterval(this.timerCode);
    },
    async sendCodeByFunc(func) {
      if (this.codeGiveTimeLimit <= 0) {
        // 发送验证码
        const [err, res] = await to(func());
        if (!err) {
          // 开始倒计时
          clearInterval(this.timerCode);
          this.codeGiveTimeLimit = 60;
          this.timerCode = setInterval(() => {
            this.codeGiveTimeLimit--;
          }, 1000);
          Toast({
            message: "验证码已发送"
          });
          return true;
        }
      }
      return false;
    },
    async sendCode(data) {
      if (this.codeGiveTimeLimit <= 0) {
        // 发送验证码
        const [err, res] = await to(apiSendCode(data));
        if (res && res.status === 200) {
          // 开始倒计时
          clearInterval(this.timerCode);
          this.codeGiveTimeLimit = 60;
          this.timerCode = setInterval(() => {
            this.codeGiveTimeLimit--;
          }, 1000);
          Toast({
            message: "验证码已发送"
          });
          return true;
        } else {
          Toast.fail({
            message: "验证码发送失败"
          });
          return false;
        }
      }
      return false;
    },
    async getCarouselList() {
      const [err, res] = await to(apiGetCarousel());
      const data = res?.data?.items || [];
      this.carouselList = data.map((m) => {
        return {
          ...m,
          to: "/notice"
        };
      });
    },
    async getCategoryList() {
      const [err, res] = await to(apiGetGoodsCategory());
      const data = res?.data?.value;
      if (data) {
        try {
          const list: any[] = JSON.parse(data);
          // 追加一个全部分类
          list.unshift({
            name: "全部",
            value: 0
          });
          this.categoryList = list;
        } catch (error) {}
      }
    }
  }
});
